@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;700&display=swap');

:root .container,
:root .row,
:root .col {
  --bs-gutter-x: 2rem;
}



body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #656a76;
}

h1, h2, h3, h4 {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  color: #051A3F;
}

h1 {
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 24px;
}

h2 {
  font-size: 24px;
  line-height: 32px;
}

h3 {
  font-size: 20px;
  line-height: 28px;
}

h4 {
  font-size: 18px;
  line-height: 26px;
}
